//签名工具函数
/**
 * 安装依赖：
 * cnpm i web3 -S
 * cnpm i ethereumjs-util -S
 * cnpm i assert -S
 * cnpm i bignumber.js -S
 */

const config = require('@/config').default
const Web3 = require('web3');
const { fromRpcSig } = require('ethereumjs-util');//将eth_sign RPC方法的签名格式转换为签名参数,即将65字节的格式转成分开的r,s,v
const assert = require('assert');//assert模块是Node的内置模块，主要用于断言 http://javascript.ruanyifeng.com/nodejs/assert.html
const BigNumber = require('bignumber.js');
const { sha3, ecrecover, hashPersonalMessage, toBuffer, pubToAddress } = require('ethereumjs-util');
const { toWad , toWad2 , fromWad, infinity, Side } = require('./constants');//常量模块

const WalletUtil=require('@/utils/walletUtil').default

//获取web3的实例。构造web3实例，并返回。
const getWeb3 = () => {
    const w = new Web3(web3.currentProvider);
    // const w = new Web3("http://server10.jy.mcarlo.com:8746");
    return w;
};
//转换为16进制,sha3哈希算法加密。
const sha3ToHex = message => {
    return '0x' + sha3(message).toString('hex');
};
//前面添加几个零
const addLeadingZero = (str, length) => {
    let len = str.length;
    return '0'.repeat(length - len) + str;
};
//尾部添加几个零
const addTailingZero = (str, length) => {
    let len = str.length;
    if (len == length) {
        return str;
    }
    return str + '0'.repeat(length - len);
};
//签名是否有效
//account：用户地址, signature：签名, message：hash
const isValidSignature = (account, signature, message) => {
    let pubkey;
    const v = parseInt(signature.config.slice(2, 4), 16);
    const method = parseInt(signature.config.slice(4, 6), 16);
    if (method === 0) {
        pubkey = ecrecover(
            hashPersonalMessage(toBuffer(message)),
            v,
            toBuffer(signature.r),
            toBuffer(signature.s)
        );
    } else if (method === 1) {
        pubkey = ecrecover(toBuffer(message), v, toBuffer(signature.r), toBuffer(signature.s));
    } else {
        throw new Error('wrong method');
    }

    const address = '0x' + pubToAddress(pubkey).toString('hex');
    return address.toLowerCase() == account.toLowerCase();
};


//计算率 
const calcRate = (rate) => {
    if (rate >= 0) {
        return new Number(rate).toString(16);
    }
    return (65536 - Math.abs(rate)).toString(16);
};
//生成订单数据
const generateOrderData = (
    version,
    isSell,
    isMarket,
    expiredAtSeconds,
    makerFeeRate,
    takerFeeRate,
    salt,
    isMakerOnly,
    isInversed,
    chainId
) => {
    let res = '0x';
    res += addLeadingZero(new BigNumber(version).toString(16), 2);
    res += isSell ? '01' : '00';
    res += isMarket ? '01' : '00';
    res += addLeadingZero(new BigNumber(expiredAtSeconds).toString(16), 5 * 2);
    res += addLeadingZero(calcRate(makerFeeRate), 2 * 2);
    res += addLeadingZero(calcRate(takerFeeRate), 2 * 2);
    res += addLeadingZero(new BigNumber(0).toString(16), 2 * 2); //What's this?
    res += addLeadingZero(new BigNumber(salt).toString(16), 8 * 2);
    res += isMakerOnly ? '01' : '00';
    res += isInversed ? '01' : '00';
    res += addLeadingZero(new BigNumber(chainId).toString(16), 8 * 2);
    return addTailingZero(res, 66);
};

const EIP712_DOMAIN_TYPEHASH = sha3ToHex('EIP712Domain(string name,uint256 chainId,string version)')
const EIP712_ORDER_TYPE = sha3ToHex(
    "Order(address trader,address broker,address perpetual,uint256 amount,uint256 price,bytes32 data)"
);

const chainId=Number(config.chainId);
//获取域分隔符 
const getDomainSeparator = () => {
    return sha3ToHex(
      EIP712_DOMAIN_TYPEHASH +
        sha3ToHex('Meke Protocol').slice(2) +
        addLeadingZero(chainId.toString(16), 64) +
        sha3ToHex('1.0').slice(2)
    )
};

//获取EIP712消息哈希
const getEIP712MessageHash = message => {
    return sha3ToHex('0x1901' + getDomainSeparator().slice(2) + message.slice(2), {
        encoding: 'hex'
    });
};
//获取订单哈希
const getOrderHash = order => {
    return getEIP712MessageHash(
        sha3ToHex(
            EIP712_ORDER_TYPE +
            addLeadingZero(order.trader.slice(2), 64) +
            addLeadingZero("0x0000000000000000000000000000000000000000".slice(2), 64) +
            addLeadingZero(order.perpetual.slice(2), 64) +
            addLeadingZero(new BigNumber(order.amount).toString(16), 64) +
            addLeadingZero(new BigNumber(order.price).toString(16), 64) +
            order.data.slice(2)
        )
    );
};

//获得订单签名
const getOrderSignature = async (order) => {
    const orderHash = getOrderHash(order);
    const newWeb3 = getWeb3();
    // console.log("orderHash:",orderHash)
    // This depends on the client, ganache-cli/testrpc auto prefix the message header to message
    // So we have to set the method ID to 0 ev'en through we use web3.eth.sign
    const signRes=await newWeb3.eth.personal.sign(orderHash, order.trader)
    // const signRes=await newWeb3.eth.sign(orderHash, order.trader)
    //签名结果
    order.signRes=signRes;
    //测试其他校验
    // const personal=await newWeb3.eth.personal.ecRecover(orderHash.toString(16),a)
    // const personal=await newWeb3.eth.accounts.recover(orderHash,a)

    const signature = fromRpcSig(signRes);
    signature.config = `0x${signature.v.toString(16)}00` + '0'.repeat(60);
    const isValid = isValidSignature(order.trader, signature, orderHash);

    assert.equal(true, isValid);
    order.signature = signature;
    order.orderHash = orderHash;
};

//到期日
const getExpiredAt = orderParam => {
    const now = Date.now() / 1000 | 0;
    const expiredAtSeconds = orderParam.expiredAtSeconds;
    const expiredAt = orderParam.expiredAt;
    if (expiredAtSeconds !== undefined && expiredAtSeconds != 0) {
        return now + expiredAtSeconds;
    }
    if (expiredAt !== undefined) {
        return expiredAt;
    }

    if(orderParam.keepMaxPrice > 0 || orderParam.keepMinPrice > 0){
        return now + 3600 * 24 * 30; //30days;
    }else{
        return now + 3600 * 24 * 3; //3days;
    }
};

//生成订单 
const buildOrder = async (orderParam, perpetual, broker) => {
    const order = {
        trader: orderParam.trader,
        broker: broker,
        perpetual: perpetual,
        amount: toWad(orderParam.amount),
        price: toWad(orderParam.price),
        data: generateOrderData(
            orderParam.version || 2,
            orderParam.side === 'sell',
            orderParam.type === 'market',
            getExpiredAt(orderParam),
            orderParam.makerFeeRate || 500,//交易费率
            orderParam.takerFeeRate || 500,//交易费率
            orderParam.salt || (Date.now()/1000|0),
            orderParam.makerOnly || false,
            orderParam.inversed || false,
            orderParam.chainId || chainId
        ),
    };
    await getOrderSignature(order);
    // console.log("签名完成：",order)
    return order;
};
/**
 * 生成订单ex
 * orderParam：表单数据（对象）。
 *      trader：用户地址
 *      amount：数量
 *      price：价格
 *      version：版本
 *      side：买：buy，卖：sell
 *      type：类型。market：市价，limit：限价
 *      expiredAt：过期日
 *      makerFeeRate：制造者费率（商家，旷工）
 *      takerFeeRate：接受者费率（用户）
 *      salt：域名分割符的手段
 *      inversed：是否倒置
 *      chainId：链Id
 * perpetual：永续合约
 * broker：交易所的地址
 */
const buildOrderEx = async (orderParam, perpetual, broker) => {
    const now = Date.parse(new Date()) / 1000 | 0;
    const order = {
        trader: orderParam.trader,      //用户地址
        broker: broker,     //交易所的地址
        perpetual: perpetual,       //永续合约
        amount: toWad(orderParam.amount),       //数量
        price: toWad(orderParam.price),         //价格
        data: generateOrderData(
            orderParam.version || 2,        //版本
            orderParam.side === 'sell',     //买：buy，卖：sell
            orderParam.type === 'market',   //类型。market：市价，limit：限价
            orderParam.expiredAt,           //过期日
            orderParam.makerFeeRate || 0,   //制造者费率（商家，旷工）
            orderParam.takerFeeRate || 0,   //接受者费率（用户）
            orderParam.salt || now,    //域名分割符的手段
            orderParam.inversed || false,   //是否倒置
            orderParam.chainId || 1         //链Id
        ),
    };
    await getOrderSignature(order);
    return order;
};


//eip712签名方式
const signTypedDataV4 = async (orderParam, perpetual, broker,addr)=>{
    console.log('参数：',orderParam, perpetual, broker)
    let order = {
        trader: orderParam.trader,
        broker: broker,
        perpetual: perpetual,
        amount: toWad(orderParam.amount),
        price: toWad(orderParam.price),
        data: generateOrderData(
              orderParam.version || 2,
              orderParam.side === 'sell',
              orderParam.type === 'market',
              getExpiredAt(orderParam),
              orderParam.makerFeeRate || 500,//交易费率
              orderParam.takerFeeRate || 500,//交易费率
              orderParam.salt || (Date.now()/1000|0),
              orderParam.makerOnly || false,
              orderParam.inversed || false,
              orderParam.chainId || chainId
          ),
      };
    const msgParams = {
      domain: {
        name: 'Meke Protocol',
        chainId: chainId.toString(),
        version: '1.0',
      },
    //   message: {
    //     Order: order
    //   },
      message:order,
      primaryType: 'Order',
      types: {
        EIP712Domain: [
          { name: 'name', type: 'string' },
          { name: 'chainId', type: 'uint256' },
          { name: 'version', type: 'string' },
        ],
        // Order: [{ name: 'Order', type: 'OrderType' }],
        // OrderType: [
        //   { name: 'trader', type: 'address' },
        //   { name: 'broker', type: 'address' },
        //   { name: 'perpetual', type: 'address' },
        //   { name: 'amount', type: 'uint256' },
        //   { name: 'price', type: 'uint256' },
        //   { name: 'data', type: 'bytes32' },
        // ],
        Order:[
          { name: 'trader', type: 'address' },
          { name: 'broker', type: 'address' },
          { name: 'perpetual', type: 'address' },
          { name: 'amount', type: 'uint256' },
          { name: 'price', type: 'uint256' },
          { name: 'data', type: 'bytes32' },
        ],
      },
    };
    // var accounts = await web3.eth.getAccounts();
    // const from = accounts[0];
    const from = addr;
    const sign = await WalletUtil.provider.request({
        method: 'eth_signTypedData_v4',
        params: [from, JSON.stringify(msgParams)],
    });
    
    const orderHash = getOrderHash(order);
    order.orderHash = orderHash;

    order.signRes=sign;
    console.log('签名：',sign)
    return order;
    // try {
    //   const from = accounts[0];
    //   const sign = await WalletUtil.provider.request({
    //     method: 'eth_signTypedData_v4',
    //     params: [from, JSON.stringify(msgParams)],
    //   });
    //   signTypedDataV4Result.innerHTML = sign;
    //   signTypedDataV4Verify.disabled = false;
    // } catch (err) {
    //   console.error(err);
    //   signTypedDataV4Result.innerHTML = `Error: ${err.message}`;
    // }
}


module.exports = {
    getOrderHash,
    getOrderSignature,
    buildOrder,
    getDomainSeparator,
    EIP712_DOMAIN_TYPEHASH,
    getEIP712MessageHash,

    signTypedDataV4,
};


