<template>
<table class="POMCshop" style="width:100%;height:100%;word-break:break-all;" :style="tableStyle">
    <!--表格标题-->
    <thead>
        <tr>
            <tt v-for="(item,index) in columns" :key="index" ><!--:class="item.title==='价格'?'flexCenter':''"-->
                <div>{{item.title}}</div>
                <!--标题项右边的tag-->
                <span v-if="item.tag" class="tag ml5">{{blockchain.abbreviation}}</span>
                <div>
                    <Tooltip v-if="item.message&& item.message!=''" max-width="250" class="ml10" :placement="item.message.placement?item.message.placement:'bottom'" >
                        <div slot="content">
                            <p>{{item.message.title}}</p>
                            <p class="tooltipContent">{{item.message.content}}</p>
                        </div>
                        <div class="tooltipIcon"><Icon size="10" type="md-help" /></div>
                    </Tooltip>
                </div>
            </tt>
        </tr>
    </thead>
    <!--表格内容-->
    <tbody style="flex:1;overflow-y:auto;" @scroll="scrollEvent">
        <tr v-for="(item,index) in tableData" :key="index">
             <slot v-bind:scope="item"></slot>
        </tr>
        <tr v-if="tableData.length==0" style="border:none;">
            <div class="control">
                {{this.$t("youNoHave")}}{{noData}}
            </div>
        </tr>
    </tbody>
</table>
</template>
<script>
/**
 * 触底事件：scrollBottom
 */
export default {
    name: '',
    props: {
        //表格标题列表
        columns:{
            type:Array,
            required:true
        },
        //表格数据
        tableData:{
            type:Array,
            default:()=>{
                return [];
            }
        },
        //暂无订单
        noData:{
            type:String,
            default:'暂无数据。'
        },
        //自定义表格table样式
        tableStyle:{
            type:Object,
            default:()=>{}
        }
    },
    components: {
    },
    data() {
        return {
    }
    },
    computed:{
        blockchain(){
            return this.$store.state.blockchain;
        },
    },
    methods: {
        // 滚定监听
        scrollEvent(e) {
            // console.log('滚动',e.currentTarget.scrollTop,e.currentTarget.clientHeight,e.currentTarget.scrollHeight);
            if (Math.ceil(e.currentTarget.scrollTop + e.currentTarget.clientHeight) >=e.currentTarget.scrollHeight) {   //容差：20px
                // console.log('滚动到底部');
                this.$emit('scrollBottom');
            }
        }
    },
    mounted() {
    },
};
</script>
<style lang='less' scoped>
.POMCshop{
    .flexColumn;
}
.POMCshop tr{
    display: flex;
    border-bottom: 1px solid @borderColor;
    padding: 0 20px;flex:1;width:100%;
    gap:16px;
    >tt{height: 40px;.cloc; display: flex;align-items: center;font-weight: 700;;flex:1; white-space:nowrap;
        .tooltipContent{.f12;.mt5;line-height: 21px;}
        .tooltipIcon{display: none; border-radius: 50%;border:1px solid @border-color-base;
        }
        &:hover{cursor: pointer; color:@success-color;}
        &:hover .tooltipIcon{border:1px solid @success-color;display: flex;align-items: center;}
    }
    >tt:nth-of-type(4) {flex:1 5%;}
    >td:nth-of-type(4) {flex:1 5%;}
    >td{height: 50px;display: flex;align-items: center;flex:1;
        >img{width:20px;height: 20px;.mr10;}
        .POMCSCitem{
            .POMCSCitemContent{.op5;}
        }
    }
    
}
.control{.flexCenter;width:100%;height: 10vh; .f16;}

</style>